import UpperLine from "./upperline";

const Header = ({ id, heading, background }) => {
  return (
    <header className="header" id={id}>
      <img className="header-bg" src={background} alt="header background" />
      <h1 className="main-heading">{heading}</h1>
    </header>
  );
};
export default Header;
