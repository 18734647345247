import MainHeader from "../main-header";
import portrait from "../../assets/img/portrait-zoom.png";
import { Trans } from "react-i18next";
import triangle from "../../assets/icons/triangle.svg";
import check from "../../assets/icons/check.svg";
import serviceDescBackground from "../../assets/img/background1.jpg";
import ContactModal from "../contactModal";
import ContactBanner from "../contactBanner";
import Underline from "../underline";
import SelfIntroduction from "../self-introduction";
import Specialty from "../specialty";
import Recommendations from "../recommendations";
import Faq from "../faq";

const Home = ({ translationParams, contactParams }) => {
  const { t } = translationParams;

  // console.log(languages);
  return (
    <>
      <div className="page" id="home">
        <MainHeader heading={t("site_name")} />
        <Specialty translationParams={translationParams} />

        <SelfIntroduction
          portrait={portrait}
          translationParams={translationParams}
        />

        {/* <Recommendations translationParams={translationParams} /> */}
        <ContactBanner contactParams={contactParams} />
        <Faq translationParams={translationParams} />
        {/* <a href="#" className="up-btn">
          <i className="icon bi bi-caret-up-square"></i>
        </a> */}
      </div>
    </>
  );
};
export default Home;
