import backgroundTop from "../assets/img/self-introduction-bg-top.png";
import { Trans } from "react-i18next";
import UpperLine from "./upperline";

const SelfIntroduction = (props) => {
  const { portrait, translationParams } = props;

  // get here dir params with hook or

  let dir = translationParams.i18n.dir();

  return (
    <section id="self-introduction">
      <img
        id="si-top-bg"
        className={"section-bg" + " " + dir}
        src={backgroundTop}
        alt="section background"
      />
      <div id="introduction-grid" className="row">
        <div className="self-introduction-img col-md-3">
          <img
            className="rounded-circle portrait"
            src={portrait}
            alt="owner portrait"
          />
        </div>
        <div className="self-introduction-text col-md-7">
          <h2 className="large-heading dark-green">
            <Trans
              i18nKey="main.presentation.selfIntroduction.heading"
              components={{ br: <br />, wbr: <wbr /> }}
            />
          </h2>
          <p className="mt-3 dark-green">
            <Trans
              i18nKey="main.presentation.selfIntroduction.content"
              components={{ br: <br />, wbr: <wbr /> }}
            />
          </p>
        </div>
      </div>
    </section>
  );
};
export default SelfIntroduction;
