import { NavLink } from "react-router-dom";
// import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logoW from "../assets/logos/logoW.png";
import logoG from "../assets/logos/logoG.png";
import facebookIconWhite from "../assets/icons/facebook-icon-white.png";
import instagramIconWhite from "../assets/icons/instagram-icon-white.png";
import phoneIconWhite from "../assets/icons/phone-icon-white.png";
import globe from "../assets/icons/globe.svg";
import tr from "../i18n";
import UpperLine from "./upperline";
import SideLine from "./sideline";
import LngBtn from "./lngBtn";

const Navbar = ({ translationParams, contactParams }) => {
  const { t, i18n, isRtl } = translationParams;
  const { contactShow, handleModalShow } = contactParams;
  const { languages } = tr;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <LngBtn id="lng-menu-sm" translationParams={translationParams} />
      <div id="nav-logo-container-sm" className="nav-component">
        <img id="nav-logo-sm" src={logoW} alt="site logo" />
      </div>
      <nav
        id="navbar"
        aria-label="Primary"
        className="navbar navbar-expand-lg "
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="bi bi-list nav-toggler-btn"></span>
          </button>
          <div
            className={`offcanvas offcanvas-${isRtl ? "end" : "start"}`}
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                <img id="menu-logo" src={logoG} alt="business logo" />
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="navbar-nav nav-component" id="nav-links">
                <NavLink to={"/"} className="nav-link">
                  <UpperLine />
                  <SideLine />
                  <button
                    type="button"
                    data-bs-dismiss="offcanvas"
                    className="unstyled-btn link-text"
                  >
                    {t("navbar.menu.main")}
                  </button>
                </NavLink>
                {/* <NavLink to={"/services"} className="nav-link">
                <UpperLine />
                <SideLine />
                <button
                type="button"
                data-bs-dismiss="offcanvas"
                className="unstyled-btn link-text"
                >
                {t("navbar.menu.services")}
                </button>
                </NavLink>
                <NavLink to={"/blog"} className="nav-link">
                <UpperLine />
                <SideLine />
                
                <button
                type="button"
                data-bs-dismiss="offcanvas"
                className="unstyled-btn link-text"
                >
                  {t("navbar.menu.blog")}
                  </button>
                </NavLink> */}

                <a
                  onClick={() => {
                    handleModalShow(contactShow);
                  }}
                  className="nav-link"
                >
                  <button
                    type="button"
                    data-bs-dismiss="offcanvas"
                    className="unstyled-btn link-text"
                  >
                    {t("navbar.menu.contact")}
                  </button>
                </a>
              </div>

              <div id="nav-logo-container" className="nav-component">
                <img id="nav-logo" src={logoW} alt="site logo" />
              </div>
              <div id="social" className="nav-component">
                <LngBtn
                  id={"lng-menu-lg"}
                  translationParams={translationParams}
                />
                <a className="social-link" href="tel:+972544658644">
                  <img
                    className="social-icon"
                    src={phoneIconWhite}
                    alt="phone call link"
                  />
                </a>
                <a
                  className="social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t("contact.links.instagram")}
                >
                  <img
                    className="social-icon"
                    src={instagramIconWhite}
                    alt="instagram link"
                  />
                </a>
                <a
                  className="social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t("contact.links.facebook")}
                >
                  <img
                    className="social-icon"
                    src={facebookIconWhite}
                    alt="facebook link"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
