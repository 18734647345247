import background from "../assets/img/under-main-banner.png";
import iconBanner from "../assets/img/heart-ils.png";
import { Trans } from "react-i18next";

const Specialty = ({ translationParams }) => {
  return (
    <section id="specialty">
      <img className="section-bg" src={background} alt="section background" />
      <div className="div-container">
        <img
          id="specialty-banner"
          src={iconBanner}
          alt="graphic illustration"
        />
        <h3 className="large-heading dark-green">
          <Trans
            i18nKey={"main.specialty.heading"}
            components={{ br: <br /> }}
          />
        </h3>
        <p className="mt-3 dark-green">
          <Trans
            i18nKey={"main.specialty.paragraph"}
            components={{ br: <br /> }}
          />
        </p>
      </div>
    </section>
  );
};
export default Specialty;
