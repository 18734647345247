import { t } from "i18next";
import { Trans } from "react-i18next";

const ContactBanner = ({ contactParams }) => {
  const { contactShow, handleModalShow } = contactParams;

  return (
    <>
      <div className="contact-banner">
        <div className="d-flex justify-content-end contact-banner-header-container">
          {/* <p className="contact-banner-header">{t("contact.banner.header")}</p> */}
          <p className="contact-banner-header">
            <Trans
              i18nKey={"contact.banner.header"}
              components={{ br: <br />, wbr: <wbr /> }}
            />
          </p>
        </div>
        <div className="d-flex justify-content-center contact-banner-button-container">
          <button
            onClick={() => {
              handleModalShow(contactShow);
            }}
            type="button"
            className="btn btn-outline-light contact-banner-btn"
          >
            {t("contact.banner.button")}
          </button>
        </div>
      </div>
    </>
  );
};
export default ContactBanner;
