import mainBanner from "../assets/img/main-banner.png";

const MainHeader = ({ heading }) => {
  return (
    <div className="header" id="main-header">
      <img id="main-header-bg" src={mainBanner} alt="header background" />
      <h1 className="main-heading">{heading}</h1>
    </div>
  );
};
export default MainHeader;
