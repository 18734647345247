import Header from "../header";
import blogBanner from "../../assets/img/blog-banner.jpg";

const Blog = () => {
  return (
    <div className="page" id="blog">
      <Header id={"blog-header"} heading={"blog"} background={blogBanner} />
      <h1 className="main-heading">Blog div</h1>
    </div>
  );
};
export default Blog;
