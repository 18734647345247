import "./styles/App.css";
import Navbar from "./components/navbar";
import Home from "./components/pages/home";
import { Route, Routes } from "react-router-dom";
import { Suspense, useEffect, useMemo, useState } from "react";
import Blog from "./components/pages/blog";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useTranslation } from "react-i18next";
import Dashboard from "./components/pages/dashboard";
import ContactModal from "./components/contactModal";
import tr from "./i18n";
import customHooks, { useIsRtl } from "./hooks/languageHooks";
import LngBtn from "./components/lngBtn";

function App() {
  const [contactShow, setContactShow] = useState(false);
  const [currentLng, setCurrentLng] = useState({});
  const [isRtl, setIsRtl] = useState();

  const { t, i18n } = useTranslation();
  const { languages } = tr;
  const translationParams = { t, i18n, languages, currentLng, isRtl };

  // console.log(i18n.language);

  // make as a tool
  (function () {
    let images = document.getElementsByTagName("img");
    for (let img of images) {
      img.setAttribute("draggable", false);
      img.setAttribute("ondragstart", "return false;");
      // console.log(img);
    }
  })();

  const rtlStatus = useIsRtl();
  useEffect(() => {
    setIsRtl(rtlStatus);
  }, [rtlStatus]);

  // useEffect(() => {
  //   console.log(i18n.language);
  // }, [i18n.language]);

  useEffect(() => {
    i18n.dir() === "ltr"
      ? (document.body.dir = "ltr")
      : (document.body.dir = "rtl");
  }, [i18n.language]);

  // const findCurrentLng = () => {
  //   let currentLng;
  //   currentLng = languages.find((lng) => {
  //     return lng.tag === i18n.language;
  //   });
  //   return currentLng;
  // };

  // const currentLanguage = useMemo(() => {
  //   let language = findCurrentLng();
  //   console.log(language);
  //   typeof language == "object"
  //     ? setCurrentLng(language)
  //     : console.error("language mismatch");
  // }, [i18n.language]);

  function handleModalShow(contactShow) {
    return setContactShow(!contactShow);
  }

  const contactParams = {
    contactShow,
    handleModalShow,
  };

  // customHooks.getDir();

  // Make it a tool

  return (
    <div className="App">
      <div className="main">
        {/* <LngBtn translationParams={translationParams} /> */}
        <Navbar
          translationParams={translationParams}
          contactParams={contactParams}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                contactParams={contactParams}
                translationParams={translationParams}
              />
            }
          />
          <Route
            path="/blog"
            element={<Blog translationParams={translationParams} />}
          />
          <Route path="/admin" element={<Dashboard />} />
        </Routes>
        <ContactModal
          contactParams={contactParams}
          translationParams={translationParams}
        />
      </div>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="... loading">
      <App />
    </Suspense>
  );
}
