// import { i18n } from "i18next";

import tr from "../i18n";
const { i18n, languages } = tr;

export const useGetDir = () => {
  const dir = i18n.dir();
  if (dir === "rtl" || dir === "ltr") {
    return dir;
  } else {
    // You can throw an error or handle this case differently
    console.error("Invalid direction:", dir);
    return null;
  }
};

export const useIsRtl = () => {
  const n = useGetDir();
  return n === "rtl" ? true : false;
};

const lngHooks = {
  useGetDir,
  useIsRtl,
};

export default lngHooks;
