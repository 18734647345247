import tr from "../i18n";
import globe from "../assets/icons/globe.svg";

const LngBtn = ({ id, translationParams }) => {
  const { t, i18n, isRtl } = translationParams;
  const { languages } = tr;

  // make it a tool
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div id={id} className="lng-dropdown dropdown-center">
      <a
        className="lng-drop-down"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        // data-bs-offset="30,20"
      >
        <img
          style={{ color: "#E16160" }}
          className="social-icon"
          src={globe}
          alt="language change button"
        />
      </a>

      <ul className="dropdown-menu">
        {languages.map((lng) => {
          return (
            <li
              key={lng.name}
              className="lng-btn dropdown-item"
              onClick={() => {
                changeLanguage(lng.tag);
              }}
            >
              <button
                className="unstyled-btn"
                // For use in Offcanvas only!!!
                // data-bs-dismiss="offcanvas"
              >
                {lng.native}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LngBtn;
