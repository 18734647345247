import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    // Change in build / dev
    debug: false,
    fallbackLng: "he",
    supportedLngs: ["he", "ru"],
    detection: {
      order: [
        "localStorage",
        "cookie",
        "navigator",
        "htmlTag",
        // "htmlTag",
        // "path",
        // "subdomain",
      ],
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",

      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
      // loadPath: "../economy-grow/assets/locales/{{lng}}/translation.json",
    },
  });
const languages = [
  { tag: "he-IL", name: "Hebrew", native: "עברית", dir: "rtl" },
  { tag: "ru-RU", name: "Russian", native: "русский ", dir: "ltr" },
];

const tr = { i18n, languages };

export default tr;
