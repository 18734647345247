import { Trans } from "react-i18next";
import background from "../assets/img/work-in-progresse2.png";

const Faq = ({ translationParams }) => {
  const { t, i18n } = translationParams;
  return (
    <section id="faq">
      <h2 className="main-heading">
        <Trans i18nKey="workInProgresse.title" components={{ br: <br /> }} />
      </h2>
      {/* <p></p> */}
      <img
        id="faq-bg"
        className="section-bg"
        src={background}
        alt="section background"
      />
    </section>
  );
};
export default Faq;
