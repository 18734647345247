import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import whatsAppBtn from "../assets/img/WhatsAppButtonGreenLarge.svg";

const ContactModal = ({ contactParams, translationParams }) => {
  const { contactShow, handleModalShow } = contactParams;
  const { t } = translationParams;

  // t("contact.fields", { returnObjects: true }).map((obj) => {
  //   console.log(obj);
  // });

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={contactShow}
        onHide={() => handleModalShow(contactShow)}
        backdrop="static"
        keyboard={"escape"}
      >
        <Modal.Header>
          <Modal.Title>{t("contact.header")}</Modal.Title>
          {/* <img src={logoW} alt="" /> */}
        </Modal.Header>

        <form action="https://formspree.io/f/mbjevwwl" method="POST">
          <Modal.Body>
            {t("contact.fields", { returnObjects: true })?.map((obj) => {
              const { name, type, id, placeholder } = obj;
              return (
                <div className="form-floating mb-3" key={id}>
                  <input
                    type={type}
                    name={name}
                    className="form-control"
                    id={id}
                    placeholder={placeholder}
                  />
                  <label htmlFor={id}>{placeholder}</label>
                </div>
              );
            })}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <div>
              <a
                target="_blank"
                href={`https://wa.me/+972544555574?text=${t(
                  "contact.whatsappMsg"
                )}`}
              >
                <img src={whatsAppBtn} alt="whatsapp contact button" />
              </a>
            </div>
            <div>
              <Button
                className="mx-1"
                onClick={() => handleModalShow(contactShow)}
                variant="secondary"
              >
                {t("contact.buttons.close")}
              </Button>
              <Button className="mx-1" type="submit" variant="primary">
                {t("contact.buttons.send")}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ContactModal;
